import React from "react";
import {
    StyledContentWrapper,
    StyledText,
    StyledHeading,
    StyledList,
    StyledUl
} from "../style";

const ContentTOU = ({ langObject }) => {
    return(
        <StyledContentWrapper>
            <StyledText>Updated: {langObject.privacyPolicy.updated}</StyledText>
            <StyledText margin={"25px"} align={langObject.align}>{langObject.privacyPolicy.introduction}</StyledText>
            <StyledHeading align={langObject.align }>{langObject.intellectualTitle}</StyledHeading>
            <StyledText align={langObject.align }>{langObject.content}</StyledText>

            <StyledHeading align={langObject.align }>{langObject.useTitle}</StyledHeading>
            <StyledText align={langObject.align }>{langObject.useContent}</StyledText>

            <StyledHeading align={langObject.align }>{langObject.responsibilityTitle}</StyledHeading>
            <StyledUl align={langObject.align}>
                <StyledList align={langObject.align}>{langObject.bullet1}</StyledList>
                <StyledList align={langObject.align}>{langObject.bullet2}</StyledList>
            </StyledUl>

            <StyledHeading align={langObject.align }>{langObject.contentAccuracyTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.contentAccuracyContent}</StyledText>

            <StyledHeading align={langObject.align }>{langObject.linksTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.linkesContent}</StyledText>

            <StyledHeading align={langObject.align }>{langObject.modificationTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.modificationContent}</StyledText>

            <StyledHeading align={langObject.align }>{langObject.warrantiesTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.warrantiesContent}</StyledText>

            <StyledHeading align={langObject.align }>{langObject.limitationOfLiabilitiesTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.limitationsContent}</StyledText>

            <StyledHeading align={langObject.align }>{langObject.governingLawTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.governingContent}</StyledText>

            <StyledHeading align={langObject.align }>{langObject.severabilityTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.severabilityContent}</StyledText>

            <StyledHeading align={langObject.align }>{langObject.waiverTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.waiverContent}</StyledText>

            <StyledHeading align={langObject.align }>{langObject.contactUsTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.contactContent}</StyledText>
        </StyledContentWrapper>
    );
}

export default ContentTOU;