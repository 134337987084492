import './App.css';
import Router from "./router";
import { ThemeProvider  } from 'styled-components';

const themes = {
  black: {
    backgroundColor: "black",
    textColor: "White",
    subHeadingColor: "#aaa",
    inActiveDotColor: "#aaaaaa"
  },
  white: {
    backgroundColor: "#F5F5F7",
    textColor: "black",
    subHeadingColor: "black",
    inActiveDotColor: "#aaaaaa"
  }
}

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={themes.white}>
        <Router/>
      </ThemeProvider>
    </div>
  );
}

export default App;
