import { Link } from "react-router-dom"
import styled, { css } from "styled-components";
import { textAlign, align } from "../../constants";

export const StyledLink = styled(Link)`
  color: ${props => props.theme.textColor};
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin-top: ${props => props.margin + "px"};
  text-align: ${props => textAlign[props.align]}

  ${(props) => css`
    @media (min-width: 426px) and (max-width: 768px) {
      /* Add your styles for medium screens here */
      font-size: 18px;
      line-height: 20.7px;
    }

    @media (min-width: 376px) and (max-width: 425px) {
      /* Add your styles for medium screens here */
      font-size: 16px;
      line-height: 18.4px;
    }

    @media (max-width: 375px) {
      /* Add your styles for medium screens here */
      font-size: 14px;
      line-height: 16.1px;
    }
  `}
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: ${props => align[props.align]};

  ${(props) => css`
    @media (min-width: 1440px) and (max-width: 1919px) {
      /* Add your styles for medium screens here */
      margin-top: 38px;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
      /* Add your styles for medium screens here */
      margin-top: 36px;
    }

    @media (min-width: 1280px) and (max-width: 1366px) {
      /* Add your styles for medium screens here */
      margin-top: 34px;
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
      /* Add your styles for medium screens here */
      margin-top: 32px;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
      /* Add your styles for medium screens here */
      margin-top: 30px;
    }

    @media (min-width: 768px) and (max-width: 800px) {
      /* Add your styles for medium screens here */
      margin-top: 28px;
    }

    @media (min-width: 600px) and (max-width: 768px) {
      /* Add your styles for medium screens here */
      margin-top: 26px;
    }

    @media (min-width: 480px) and (max-width: 600px) {
      /* Add your styles for medium screens here */
      margin-top: 24px;
    }

    @media (min-width: 414px) and (max-width: 480px) {
      /* Add your styles for medium screens here */
      margin-top: 22px;
    }

    @media (min-width: 375px) and (max-width: 414px) {
      /* Add your styles for medium screens here */
      margin-top: 20px;
    }

    @media (min-width: 360px) and (max-width: 375px) {
      /* Add your styles for medium screens here */
      margin-top: 20px;
    }

    @media (min-width: 320px) and (max-width: 360px) {
      /* Add your styles for medium screens here */
      margin-top: 18px;
    }
  `}
`;
