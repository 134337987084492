import {useMemo, } from "react";
import { languages } from "../constants";
import { useParams } from 'react-router-dom';

const useGetLanguage = (page) => {
  const { lang: language } = useParams();
  localStorage.setItem("lang", language);
    return useMemo(() => {
        const langObject = languages[page];
        return langObject[language] ? langObject[language]: langObject["en"];
    },[language, page]);
}

export default useGetLanguage;