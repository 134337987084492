import styled, { css } from 'styled-components';
export const StyledSpan = styled.span`
    font-size: 25px;
    color: ${props => props.theme.textColor};
  ${(props) => css`
    @media (min-width: 1440px) and (max-width: 1919px) {
      /* Add your styles for medium screens here */
      font-size: 25px;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
      /* Add your styles for medium screens here */
      font-size: 25px;
    }

    @media (min-width: 1280px) and (max-width: 1366px) {
      /* Add your styles for medium screens here */
      font-size: 25px;
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
      /* Add your styles for medium screens here */
      font-size: 25px;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
      /* Add your styles for medium screens here */
      font-size: 20px;
    }

    @media (min-width: 768px) and (max-width: 800px) {
      /* Add your styles for medium screens here */
      font-size: 20px;
    }

    @media (min-width: 600px) and (max-width: 768px) {
      /* Add your styles for medium screens here */
      font-size: 18px;
    }

    @media (min-width: 480px) and (max-width: 600px) {
      /* Add your styles for medium screens here */
      font-size: 18px;
    }

    @media (min-width: 414px) and (max-width: 480px) {
      /* Add your styles for medium screens here */
      font-size: 18px;
    }

    @media (min-width: 375px) and (max-width: 414px) {
      /* Add your styles for medium screens here */
      font-size: 18px;
    }

    @media (min-width: 360px) and (max-width: 375px) {
      /* Add your styles for medium screens here */
      font-size: 18px;
    }

    @media (min-width: 320px) and (max-width: 360px) {
      /* Add your styles for medium screens here */
      font-size: 16px;
    }
  `};
`;

export const StyledDiv = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.backgroundColor};
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLine = styled.div`
    height: ${props => props.height};
    background-color: ${props => props.isBlack ? props.theme.backgroundColor: props.theme.textColor}
`;

export const StyledAnchor = styled.a`
  font-size: 25px;
  color: ${props => props.theme.textColor};
  ${(props) => css`
    @media (min-width: 1440px) and (max-width: 1919px) {
      /* Add your styles for medium screens here */
      font-size: 25px;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
      /* Add your styles for medium screens here */
      font-size: 25px;
    }

    @media (min-width: 1280px) and (max-width: 1366px) {
      /* Add your styles for medium screens here */
      font-size: 25px;
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
      /* Add your styles for medium screens here */
      font-size: 25px;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
      /* Add your styles for medium screens here */
      font-size: 20px;
    }

    @media (min-width: 768px) and (max-width: 800px) {
      /* Add your styles for medium screens here */
      font-size: 20px;
    }

    @media (min-width: 600px) and (max-width: 768px) {
      /* Add your styles for medium screens here */
      font-size: 18px;
    }

    @media (min-width: 480px) and (max-width: 600px) {
      /* Add your styles for medium screens here */
      font-size: 18px;
    }

    @media (min-width: 414px) and (max-width: 480px) {
      /* Add your styles for medium screens here */
      font-size: 18px;
    }

    @media (min-width: 375px) and (max-width: 414px) {
      /* Add your styles for medium screens here */
      font-size: 18px;
    }

    @media (min-width: 360px) and (max-width: 375px) {
      /* Add your styles for medium screens here */
      font-size: 18px;
    }

    @media (min-width: 320px) and (max-width: 360px) {
      /* Add your styles for medium screens here */
      font-size: 16px;
    }
  `};
`