import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { VizionText, StyledSelect, StickyNav, NavContent } from "./style";
import { languages } from "../constants";

const Navbar = ({page}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSticky, setSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      // setSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <StickyNav sticky={isSticky}>
      <NavContent>
        <StyledSelect
          sticky={isSticky}
          value={localStorage.getItem("lang")}
          onChange={(e) => {
            const parts = location.pathname.split("/");
            navigate(`/${parts[1]}/${e.target.value}`);
          }}
        >
          {Object.keys(languages[page]).map((key) => (
            <option value={key}>{languages[page][key].label}</option>
          ))}
        </StyledSelect>
      </NavContent>
    </StickyNav>
  );
};

export default Navbar;
