import React from "react";
import { PageContainer, MobileImage } from "./style";
import Navbar from "./navbar";
import RightsSection from "./RightsSection";
import LinesSection from "./LinesSection";
import HomePageBranding from "./HomePageBranding";
import fbImage from "../assets/facebook.svg";
import xImage from "../assets/x.svg";
import linkedinImage from "../assets/linkedin.svg";

const PageWrapper = ({ children, page, showHomePageBranding = true }) => {
  return (
    <>
      <PageContainer>
        <Navbar page={page} />
        {showHomePageBranding && <HomePageBranding />}
        {children}
        <div style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:"60px", marginTop:"100px"}}>
        <a href="https://www.facebook.com/easyConsentApp" target="_blank">
          <MobileImage src={fbImage} style={{width:"30px", cursor:"pointer"}} />
        </a>
        <a href="https://x.com/easyConsent" target="_blank">
          <MobileImage src={xImage} style={{width:"30px", cursor:"pointer"}} />
        </a>
        <a href="https://www.linkedin.com/company/easyconsent/posts/?feedView=all" target="_blank">
          <MobileImage src={linkedinImage} style={{width:"30px", cursor:"pointer"}} />
        </a>
      </div>
        {/* <RightsSection /> */}
      </PageContainer>
      <LinesSection />
    </>
  );
};

export default PageWrapper;
