import React from "react";
import { StyledLink, LinkWrapper } from "./styles";
import { useParams } from "react-router-dom";
import {
  MainHeadline,
  MainHeadlineWrapper,
  SubHeadlineWrapper,
  SubHeadline,
} from "../style";


const LegalSection = ({ langObject = {} }) => {
  const { lang } = useParams();
  return (
    <>
      <MainHeadlineWrapper>
        <MainHeadline >{langObject.legalInformationHeadline}</MainHeadline>
      </MainHeadlineWrapper>
      <SubHeadlineWrapper >
        <SubHeadline >
          {langObject.legalInformationSubHeadline}
        </SubHeadline>
      </SubHeadlineWrapper>
      <LinkWrapper >
        <StyledLink target="_blank"  to={`/terms/${lang}`} margin={0}>{langObject.termsOfUseText}</StyledLink>
        <StyledLink target="_blank"  to={`/privacy-policy/${lang}`} margin={32}>{langObject.privacyPolicyText}</StyledLink>
        <StyledLink target="_blank"  to={`/cookie-policy/${lang}`} margin={32}>{langObject.cookiePolicy}</StyledLink>
      </LinkWrapper>
      
    </>
  );
};

export default LegalSection;
