import { 
    MainHeadline,
    MainHeadlineWrapper,
    SubHeadlineWrapper,
    SubHeadline,
    SubSubHeadlineWrapper,
    SubSubHeadline
} from "../style";

const PioneersSection = ({ langObject = {}, audio }) => {
    return(
        <div>
            <MainHeadlineWrapper >
                <MainHeadline >{langObject.pioneersHeading || ""}</MainHeadline>
            </MainHeadlineWrapper>
            <SubHeadlineWrapper >
                <SubHeadline >{langObject.pioneersSubHeading || ""}</SubHeadline>
            </SubHeadlineWrapper>
            <SubSubHeadlineWrapper >
                <SubSubHeadline align={"center"}>{langObject.pioneersSubSubHeading1 || ""}</SubSubHeadline>
                <SubSubHeadline align={"center"} style={{ marginTop:"10px"}}>{langObject.pioneersSubSubHeading2 || ""}</SubSubHeadline>
            </SubSubHeadlineWrapper>
        </div>
    )
}

export default PioneersSection;
