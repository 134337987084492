import styled, { css } from "styled-components";
import { textAlign, align } from "../../constants";
export const StyledHeadingWrapper = styled.div`
  width: 40%;
  display:flex;
  flex-direction: column;
  margin-top:100px;
  align-items: ${props => align[props.align]};

  ${props => css`
    @media (min-width: 1441px) and (max-width: 1919px) {
        /* Add your styles for medium screens here */
        width: 40%;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
        /* Add your styles for medium screens here */
        width: 40%;
    }

    @media (min-width: 1280px) and (max-width: 1365px) {
        /* Add your styles for medium screens here */
        width: 45%;
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
        /* Add your styles for medium screens here */
        width: 45%;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
        /* Add your styles for medium screens here */
        width: 50%;
    }

    @media (min-width: 768px) and (max-width: 800px) {
        /* Add your styles for medium screens here */
        width: 50%;
    }

    @media (min-width: 600px) and (max-width: 768px) {
        /* Add your styles for medium screens here */
        width: 55%;
    }

    @media (min-width: 480px) and (max-width: 600px) {
        /* Add your styles for medium screens here */
        width: 55%;
    }

    @media (min-width: 414px) and (max-width: 480px) {
        /* Add your styles for medium screens here */
        width: 60%;
    }

    @media (min-width: 375px) and (max-width: 414px) {
        /* Add your styles for medium screens here */
        width: 60%;
    }

    @media (min-width: 360px) and (max-width: 375px) {
        /* Add your styles for medium screens here */
        width: 65%;
    }

    @media (min-width: 320px) and (max-width: 360px) {
        /* Add your styles for medium screens here */
        width: 70%;
    }
`}

`;

export const StyledHeadingText = styled.span`
  color: ${props => props.theme.textColor};
  font-family: Arial;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: ${props => textAlign[props.align]};

  ${(props) => css`
    @media (min-width: 426px) and (max-width: 768px) {
      /* Add your styles for medium screens here */
      font-size: 32px;
      line-height: 36.8px;
    }

    @media (min-width: 376px) and (max-width: 425px) {
      /* Add your styles for medium screens here */
      font-size: 28px;
      line-height: 32.2px;
    }

    @media (max-width: 375px) {
      /* Add your styles for medium screens here */
      font-size: 24px;
      line-height: 27.6px;
    }
  `}
`;
