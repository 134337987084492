import { 
    MainHeadline,
    MainHeadlineWrapper,
    SubHeadlineWrapper,
    SubHeadline,
    SubSubHeadlineWrapper,
    MobileImage,
    LanguagesTextWrapper,
    LanguagesText
} from "../style";
import Image from "../../assets/pages.svg"
const InnovationsSection = ({ langObject = {} }) => {
    return(
        <div>
            <MainHeadlineWrapper >
                <MainHeadline >{langObject.innovationHeading || ""}</MainHeadline>
            </MainHeadlineWrapper>
            <SubHeadlineWrapper >
                <SubHeadline>{langObject.innovationSubHeading || ""}</SubHeadline>
            </SubHeadlineWrapper>
            <SubSubHeadlineWrapper>
                <MobileImage src={Image} />
            </SubSubHeadlineWrapper>
            <LanguagesTextWrapper>
                <LanguagesText>{langObject.pagesLegalText}</LanguagesText>
            </LanguagesTextWrapper>
        </div>
    )
}

export default InnovationsSection;
