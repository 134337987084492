import React from "react";
import PageContainer from "../../components/PageContainer";

import BannerTOU from "../../components/BannerTOU";
import ContentPrivacy from "../../components/ContentPrivacy";

import useGetLanguage from "../../hooks/useGetLanguage";
import { useLocation } from "react-router-dom";
import ContentAppPrivacy from "../../components/ContentAppPrivacy";

const PrivacyPolicy = () => {
  const langObject = useGetLanguage("privacy");
  const appPrivacy = useGetLanguage("appPrivacy");
  const location = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, [location.pathname]);
  return (
    <PageContainer showHomePageBranding={false}  page={"privacy"}>
      <BannerTOU langObject={langObject} align={langObject.align} text={"PRIVACY POLICY"} subHeadlineText={"easyConsent Website"} />
      <ContentPrivacy langObject={langObject} />
      <BannerTOU langObject={appPrivacy} align={appPrivacy.align} text={"PRIVACY POLICY"} subHeadlineText={"easyConsent App"} />
      <ContentAppPrivacy langObject={appPrivacy} />
    </PageContainer>
  );
};

export default PrivacyPolicy;
