import React from "react";
import {
  ContactSectionWrapper,
  StyledButton,
  PrivacyTextWrapper,
  PrivacyText,
  PrivacyContainer,
} from "./styles";
import {
  MainHeadline,
  MainHeadlineWrapper,
  SubHeadlineWrapper,
  SubHeadline,
  SubSubHeadlineWrapper,
  SubSubHeadline
} from "../style";

const ContactSection = ({ langObject = {} }) => {
  return (
    <>
      <MainHeadlineWrapper align={"center"}>
        <MainHeadline align={"center"}>
          {langObject.contactSectionHeading}
        </MainHeadline>
      </MainHeadlineWrapper>
      <SubHeadlineWrapper align={"center"}>
        <SubHeadline align={"center"}>
          {langObject.contactSectionSubHeading}
        </SubHeadline>
      </SubHeadlineWrapper>
      <SubSubHeadlineWrapper align={"center"}>
        <SubSubHeadline align={"center"}>
          {langObject.ideasText}
        </SubSubHeadline>
      </SubSubHeadlineWrapper>
      <ContactSectionWrapper>
        {/* <StyledInput type="text" align={langObject.align} placeholder={langObject.namePlaceholder} customMargin={"0px"} />
        <StyledInput
          type="text"
          placeholder={langObject.emailPlaceholder}
          customMargin={"10px"}
          align={langObject.align}
        />
        <StyledTextArea align={langObject.align} placeholder={langObject.messagePlaceholder} customMargin={"10px"} /> */}
        <StyledButton href="mailto:support@easyconsent.app" target="_blank" customMargin={"10px"}>{langObject.submitButtonText}</StyledButton>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#0FF",
            height: "150px",
            width: "150px",
            borderRadius: "50%",
          }}
        >
          <p style={{ margin: "0px", fontSize:"18px", fontWeight:"700" }}>Contact Us</p>
        </div> */}
      </ContactSectionWrapper>

      <PrivacyContainer>
        <PrivacyTextWrapper>
          <PrivacyText>{langObject.privacyText}</PrivacyText>
        </PrivacyTextWrapper>
      </PrivacyContainer>
    </>
  );
};

export default ContactSection;
