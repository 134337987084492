import React from "react";
import {
  HeadingWrapper,
  HeadingText,
  SubHeadingWrapper,
  HeadingSubText,
  SubSubHeadingWrapper,
  HeadingSubSubText,
  
} from "./styles";
import {
  SubSubHeadlineWrapper,
  MobileImage
} from "../style"
import AppleStoreImage from "../../assets/apple-store.svg"

const HomePageBanner = ({ langObject = {} }) => {
  return (
    <div>
      <HeadingWrapper>
        <HeadingText>{langObject.headingText1}</HeadingText>
        <HeadingText>{langObject.headingText2}</HeadingText>
        <HeadingText>{langObject.headingText3}</HeadingText>
      </HeadingWrapper>
      <SubSubHeadlineWrapper >
        <a href="https://apps.apple.com/us/app/easyconsent/id6479038264" target="_blank" rel="noreferrer">
        <MobileImage src={AppleStoreImage} style={{width:"157px"}} />
        </a>
      </SubSubHeadlineWrapper>
      <SubHeadingWrapper>
        <HeadingSubText>{langObject.subHeading}</HeadingSubText>
      </SubHeadingWrapper>
      <SubSubHeadingWrapper >
        <HeadingSubSubText>{langObject.subsubHeading}</HeadingSubSubText>
      </SubSubHeadingWrapper>
    </div>
  );
};
export default HomePageBanner;
