import React from "react";
import {
    StyledHeadingWrapper,
    StyledHeadingText
} from "./style";
import {
    SubHeadline,
    SubHeadlineWrapper
} from "../style"

const BannerTOU = ({ langObject, align, text, subHeadlineText }) => {
    return(
        <StyledHeadingWrapper align={"center"} >
            <StyledHeadingText align={"center"}>{text}</StyledHeadingText>
            {subHeadlineText &&  <SubHeadlineWrapper align={"center"}>
                <SubHeadline>{subHeadlineText || ""}</SubHeadline>
            </SubHeadlineWrapper>}
        </StyledHeadingWrapper>
    );
}

export default BannerTOU;
