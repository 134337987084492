import { 
    MainHeadline,
    MainHeadlineWrapper,
    SubHeadlineWrapper,
    SubHeadline,
    SubSubHeadlineWrapper,
    MobileImage,
    LanguagesTextWrapper,
    LanguagesText,
    MobileImageWithMargin
} from "../style";
import AppleStoreImage from "../../assets/apple-store.svg"
import QrCodeImage from "../../assets/qr-code.svg"
const DownloadAppSection   = ({ langObject = {} }) => {
    return(
        <div>
            <MainHeadlineWrapper >
                <MainHeadline >{langObject.downloadAppHeading || ""}</MainHeadline>
            </MainHeadlineWrapper>
            <SubHeadlineWrapper >
                <SubHeadline>{langObject.downloadAppSubHeading || ""}</SubHeadline>
            </SubHeadlineWrapper>
            <SubSubHeadlineWrapper >
                <a href="https://apps.apple.com/us/app/easyconsent/id6479038264" target="_blank" rel="noreferrer">
                    <MobileImage src={AppleStoreImage} style={{width:"157px"}} />
                </a>
                <MobileImageWithMargin src={QrCodeImage} style={{width:"157px", borderRadius:"8px"}} />
            </SubSubHeadlineWrapper>
            <LanguagesTextWrapper>
                <LanguagesText>{langObject.downloadAppText}</LanguagesText>
            </LanguagesTextWrapper>
        </div>
    )
}

export default DownloadAppSection;
